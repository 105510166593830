import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

// to get system preference
export const getFrontEndSystemPreference = async () => {
  try {
    return (await apiClient.get("systemPreference/getSystemPreference"))?.data
      ?.data;
  } catch (error) {
    handleError(error);
  }
};

// get job details
export const getJobDetails = async (jobCode?: any) => {
  try {
    const res = await apiClient.get(`/jobs/jobDetails?jobCode=${jobCode}`);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
// get similar jobs
export const getSimilarJobs = async (
  jobCode: any,
  currentPage: number,
  pageSize: number,
) => {
  try {
    const res = await apiClient.get(
      `/jobs/getSimilarJobs?jobCode=${jobCode}&currentPage=${currentPage}&pageSize=${pageSize}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// apply job

export const applyJob = async (data: any) => {
  try {
    const res = await apiClient.post("/jobs/applyJob", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
