import React from "react";

const SuitcaseIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 7.66667H4.33333M11 7.66667V9.33333M11.8333 4.33333H5.16667V1.83333C5.16667 1.61232 5.25446 1.40036 5.41074 1.24408C5.56702 1.0878 5.77899 1 6 1H11C11.221 1 11.433 1.0878 11.5893 1.24408C11.7455 1.40036 11.8333 1.61232 11.8333 1.83333V4.33333ZM16 5.16667V15.1667C16 15.6269 15.6269 16 15.1667 16H1.83333C1.3731 16 1 15.6269 1 15.1667V5.16667C1 4.70643 1.3731 4.33333 1.83333 4.33333H15.1667C15.6269 4.33333 16 4.70643 16 5.16667Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuitcaseIcon;
