import { useTranslation } from "react-i18next";
import { redirectTo } from "../../utils/Common/Constant";
import "./ApplicantSignInWrapper.scss";
import { useLocation } from "react-router-dom";
const ApplicantSignInWrapper = ({ children }: any) => {
  const route = useLocation();
  const isLoginPage = () => {
    return route?.pathname?.split("/").includes("applicant-login");
  };
  const { t } = useTranslation();
  return (
    <div className="applicantWrapper">
      <div className="container-fluid">
        <div className="row">
          <div className=" col-xl-6 mvChange">
            <div className="loginBanner container-fluid">
              <div className="row g-1">
                <div className="col-6 col-sm-6 col-md-4">
                  <div className="card visibleOne">
                    <img
                      src={require("../../utils/Images/image-1.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4">
                  <div className="card visibleTwo mrLeft">
                    <img
                      src={require("../../utils/Images/image-2.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4">
                  <div className="card visibleThree">
                    <img
                      src={require("../../utils/Images/image-3.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4">
                  <div className="card visibleOne">
                    <img
                      src={require("../../utils/Images/image-4.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4 hideOnMv forTb">
                  <div className="card visibleTwo mrLeft">
                    <img
                      src={require("../../utils/Images/image-5.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4 hideOnMv forTb">
                  <div className="card visibleThree">
                    <img
                      src={require("../../utils/Images/image-6.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4 hideOnMv forTb">
                  <div className="card visibleFour">
                    <img
                      src={require("../../utils/Images/image-7.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 hideOnMv">
                  <div className="card one mrLeft">
                    <img
                      className="logoDelay"
                      src={require("../../utils/Images/loginLogo.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4">
                  <div className="card visibleFive">
                    <img
                      src={require("../../utils/Images/image-8.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4">
                  <div className="card visibleSix">
                    <img
                      src={require("../../utils/Images/image-9.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4">
                  <div className="card visibleSeven mrLeft">
                    <img
                      src={require("../../utils/Images/image-10.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4">
                  <div className="card visibleEight">
                    <img
                      src={require("../../utils/Images/image-11.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 hideOnMv forTb">
                  <div className="card visibleOne">
                    <img
                      src={require("../../utils/Images/image-12.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4 hideOnMv forTb">
                  <div className="card visibleTwo mrLeft">
                    <img
                      src={require("../../utils/Images/image-13.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 col-md-4 hideOnMv forTb">
                  <div className="card visibleThree">
                    <img
                      src={require("../../utils/Images/image-14.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 loginCol">
            <div className="loginForm">
              <div className="right-part">
                <div className="loginFormContent">{children}</div>
              </div>
              {isLoginPage() && (
                <div className="bottomLinks">
                  <span
                    onClick={() =>
                      redirectTo("https://evluate.com/privacy-policy.php")
                    }
                  >
                    {" "}
                    {t("GENEREAL.PRIVACY_POLICY")}
                  </span>

                  <div>|</div>
                  <span
                    onClick={() =>
                      redirectTo("https://evluate.com/terms-and-use.php")
                    }
                  >
                    {t("GENEREAL.TERM_OF_USE")}{" "}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplicantSignInWrapper;
