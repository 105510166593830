import "./ApplyForJob.scss";
import LogoTagline from "../../utils/Images/LogoTagline";
import UserImage from "../../utils/Images/UserImage";
import { AssessmentButton, Bullets, showToast } from "ui-components";
import CircleCrossIcon from "../../utils/Images/CircleCrossIcon";
import {
  applyJob,
  getJobDetails,
  getSimilarJobs,
} from "../../service/Scope/Scope";
import {
  capitalizeFirstLetter,
  expandText,
  generateUUID,
  getTimeDifference,
  getTruncatedText,
  handleError,
  typesKeyPair,
} from "../../utils/Common/Constant";
import { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { MultiFileUploader } from "../../utils/Common/MediaUploader";
import Loader from "../../components/Loader/Loader";
import LinkedinIcon from "../../utils/Images/LinkedinIcon";
import JobMatchImg from "../../utils/Images/JobMatchImg";
import TeamImg from "../../utils/Images/TeamImg";
import NetworkImg from "../../utils/Images/NetworkImg";
import LocationMarkIcon from "../../utils/Images/LocationMarkIcon";
import SuitcaseIcon from "../../utils/Images/SuitcaseIcon";
import { ApplyJobEnums } from "../../utils/Enums/ApplyJobEnums";
const ApplyForJob = () => {
  const [selectedFileName, setSelectedFileName] = useState("");
  const { t } = useTranslation();
  const [jobDetails, setJobDetails] = useState<any>(null);
  const [similarJobsData, setSimilarJobsData] = useState<any>(null);
  const [totalJobCount, setTotalJobCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const [currentPage] = useState(1);
  const [isLoadingResume, SetIsLoadingResume] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const jobCode = searchParams.get("jd");
  const jobDetailRef = useRef<any>(null);
  const applyWithCvRef = useRef<any>(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const descriptionRef = useRef<any>(null);
  const getJobDetailApi = async () => {
    try {
      const res = await getJobDetails(jobCode);
      setJobDetails(res?.data);
      setPageLoading(false);
    } catch (error) {
      handleError(error);
      setPageLoading(false);
    }
  };
  const getSimilarJobsApi = async () => {
    try {
      const res = await getSimilarJobs(jobCode, currentPage, pageSize);
      setSimilarJobsData(res?.data?.data);
      setTotalJobCount(res?.data?.totalCount);
    } catch (error) {
      handleError(error);
    }
  };
  const applyJobApi = async (
    filePath: string,
    fileName: string,
    fileType: string,
    fileSize: number,
  ) => {
    const splitUrl = filePath?.split("/file/");
    const splittedPath = splitUrl?.length > 1 ? `file/${splitUrl[1]}` : "";
    const data = {
      jobId: jobDetails?.jobId,
      cvInfo: {
        filePath: splittedPath,
        fileName,
        fileType,
        fileSize,
      },
    };
    try {
      const res = await applyJob(data);
      SetIsLoadingResume(false);
      showToast(res?.customMessage, "success");
    } catch (error) {
      handleError(error);
      SetIsLoadingResume(false);
    }
  };
  useEffect(() => {
    if (jobCode) {
      getJobDetailApi();
    }
  }, [jobCode]);
  useEffect(() => {
    if (jobCode) {
      getSimilarJobsApi();
    }
  }, [pageSize, jobCode]);
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024);
      const supportedFormats = [".pdf", ".doc", ".docx", ".txt"];
      const fileExtension = file.name.substring(file.name.lastIndexOf("."));
      if (fileSizeInMB > 5) {
        showToast(t("ERROR.FILE_SIZE_GREATER"), "error");
      } else if (!supportedFormats.includes(fileExtension.toLowerCase())) {
        showToast(
          "Unsupported file format. Please upload a file in .pdf, .doc, .docx, or .txt format.",
        );
      } else {
        setSelectedFileName(file?.name);
        const uploadFile = async (file: File | any) => {
          SetIsLoadingResume(true);
          try {
            const imageData = await MultiFileUploader(
              file,
              `file/resume/${generateUUID()}`,
            );
            if (imageData?.ImageUrl) {
              const fileExtension = file?.name?.split(".").pop().toLowerCase();
              applyJobApi(
                imageData?.ImageUrl,
                file?.name,
                fileExtension,
                file?.size,
              );
            }
          } catch (error) {
            console.error("Upload failed for file:", file.name, error);
            SetIsLoadingResume(false);
          }
        };
        uploadFile(file);
        // Handle the uploaded file (e.g., upload to server)
      }
    }
  };

  const handleButtonClick = () => {
    const fileInput = document.getElementById("fileInput")!;
    fileInput.click();
  };
  const loadMoreJobs = () => {
    setPageSize(pageSize + 3);
  };
  return (
    <>
      {!pageLoading ? (
        <div className="jobPage ">
          <div className="jobLogo">
            <LogoTagline />
          </div>
          <hr className="jobHorizontalLine" />
          {/* Header Section */}
          <div className="headerDetailSec">
            {jobDetails?.companyLogo ? (
              <div className="userImageBorder companyLogo">
                <img src={jobDetails?.companyLogo} />
              </div>
            ) : (
              <div className="userImageBorder">
                <UserImage />
              </div>
            )}
            <div className="jobHeader">
              <h1>
                {jobDetails?.companyName
                  ? capitalizeFirstLetter(jobDetails?.companyName)
                  : "-"}
              </h1>
              <p>
                {jobDetails?.companyCity && jobDetails?.companyCity}
                {jobDetails?.companyState && ", " + jobDetails?.companyState}
                {jobDetails?.companyCountry &&
                  ", " + jobDetails?.companyCountry}
              </p>
            </div>
          </div>
          <div className="jobDetailsSec" ref={jobDetailRef}>
            {/* Job Details Section */}
            <div className="jobLeftContainer">
              <div className="jobDetailsContain">
                <div className="jobNameApply">
                  <h2>{jobDetails?.title}</h2>
                  <AssessmentButton
                    className="lightBtn"
                    onClick={() => {
                      applyWithCvRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                    disabled={
                      jobDetails?.status === ApplyJobEnums.JOB_STATUS_CLOSED
                    }
                  >
                    {t("GENERAL.APPLY")}
                  </AssessmentButton>
                </div>
                <div className="jobNameApply">
                  <p title={moment(jobDetails?.createdAt).format("LLL")}>
                    Posted{" "}
                    {getTimeDifference(
                      jobDetails?.createdAt,
                      t("GENERAL.MIN(S)"),
                      t("GENERAL.HOUR(S)"),
                      t("GENERAL.DAY(S)"),
                      t("GENERAL.MONTH(S)"),
                      t("GENERAL.YEAR(S)"),
                      t("GENERAL.AGO"),
                      t("GENERAL.JUST_NOW"),
                    ) ?? "-"}
                  </p>
                  {jobDetails?.status === ApplyJobEnums.JOB_STATUS_CLOSED && (
                    <p className="jobClosedText">{t("GENERAL.JOB_CLOSED")}</p>
                  )}
                </div>
                <div className="borderSec">
                  <div className="jdDetailsSec row">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="jobDetails">
                          <span className="jdTitle">
                            {t("GENERAL.EXPERIENCE")}
                          </span>
                          <span className="jdValue">
                            {jobDetails?.minimumExperience}-
                            {jobDetails?.maximumExperience}{" "}
                            {t("GENERAL.YEAR(S)")}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="jobDetails">
                          <span className="jdTitle">
                            {t("GENERAL.OPEN_POSITION")}
                          </span>
                          <span className="jdValue">
                            {jobDetails?.noOfPositions}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="jobDetails">
                          <span className="jdTitle">
                            {t("GENERAL.ENGAGEMENT_TYPE")}
                          </span>
                          <span className="jdValue">
                            {typesKeyPair[jobDetails?.engagementType]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-4">
                        <div className="jobDetails">
                          <span className="jdTitle">{t("GENERAL.MODE")}</span>
                          <span className="jdValue">
                            {typesKeyPair[jobDetails?.jobType]}
                          </span>
                        </div>
                      </div>
                      {jobDetails?.engagementType !== "remote" && (
                        <div className="col-md-4">
                          <div className="jobDetails">
                            <span className="jdTitle">
                              {t("GENERAL.LOCATION")}
                            </span>
                            <span className="jdValue">
                              {jobDetails?.jobCity && jobDetails?.jobCity}
                              {jobDetails?.jobState &&
                                ", " + jobDetails?.jobState}
                              {jobDetails?.jobCountry &&
                                ", " + jobDetails?.jobCountry}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className="smallSeperation" />
                  <div className="titleSkill">{t("GENERAL.SKILLS")}</div>
                  <div className="bulletSec mt-3 skillsSecWidth">
                    {jobDetails?.jobSkills?.map((skill: any) => (
                      <Bullets
                        key={Math.random()}
                        skillName={
                          capitalizeFirstLetter(skill?.skillName) ?? ""
                        }
                      />
                    ))}
                  </div>
                  <hr className="smallSeperation" />
                  <div className="rolesSkillsSec">
                    <span className="roleSkillsHead">
                      {t("GENERAL.ROLE_SKILLS")}
                    </span>
                    <div
                      ref={descriptionRef}
                      dangerouslySetInnerHTML={{
                        __html: isExpanded
                          ? jobDetails?.description
                          : getTruncatedText(jobDetails?.description, 25),
                      }}
                    />
                    {jobDetails?.description?.length > 0 && (
                      <span
                        onClick={() =>
                          expandText(descriptionRef, setIsExpanded, isExpanded)
                        }
                        className="readMoreBtn"
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="linkedinSec" ref={applyWithCvRef}>
                <div className="applyLinkedin">
                  <div className="cvUploadBtn">
                    <input
                      type="file"
                      id="fileInput"
                      accept=".pdf,.doc,.docx,.txt"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <AssessmentButton
                      className="lightBtn"
                      onClick={handleButtonClick}
                      disabled={
                        jobDetails?.status === ApplyJobEnums.JOB_STATUS_CLOSED
                      }
                    >
                      {t("GENERAL.APPLY_WITH_CV")}{" "}
                      {isLoadingResume && <Loader />}
                    </AssessmentButton>
                  </div>
                  <div className="supportedTypes">
                    {t("GENERAL.SUPPORTED_FORMATE")}
                  </div>
                  {selectedFileName && (
                    <div className="uploadedCv">
                      <span className="cvName">{selectedFileName}</span>
                      <div onClick={() => setSelectedFileName("")}>
                        <CircleCrossIcon />
                      </div>
                    </div>
                  )}
                </div>
                <div className="verticalLine" />
                <div className="applyWithLinkedinBtnSec">
                  <span>
                    {t("GENERAL.APPLY_WITH_LINKEDIN")} &nbsp;&nbsp;&nbsp;
                    <LinkedinIcon />
                  </span>
                </div>
              </div>
              {jobDetails?.status === ApplyJobEnums.JOB_STATUS_CLOSED && (
                <div className="jobClosedMessage">
                  <span>
                    The job is closed; you cannot upload further CVs. Please
                    upload your CV for similar jobs.
                  </span>
                </div>
              )}
              <div className="whyApplyJobSection">
                <div className="whyApply">
                  {t("GENERAL.WHY_JOB_VIA_EVLUATE")}
                </div>
                <div className="evluateFeatureElaborate row">
                  <div className="col-md-4 featureDetail">
                    <div className="image">
                      <JobMatchImg />
                    </div>
                    <div className="featureTitle">
                      {t("GENERAL.PERSONALIZED_JOB_MATCHES")}
                    </div>
                    <div className="featureDescription">
                      {t("GENERAL.PROMOTION_TEXT1")}
                    </div>
                  </div>
                  <div className="col-md-4 featureDetail">
                    <div className="image">
                      <TeamImg />
                    </div>
                    <div className="featureTitle">
                      {t("GENERAL.VERIFIED_HIRING_TEAMS")}
                    </div>
                    <div className="featureDescription">
                      {t("GENERAL.PROMOTION_TEXT2")}
                    </div>
                  </div>
                  <div className="col-md-4 featureDetail">
                    <div className="image">
                      <NetworkImg />
                    </div>
                    <div className="featureTitle">
                      {t("GENERAL.MOVE_WITH_AI")}
                    </div>
                    <div className="featureDescription">
                      {t("GENERAL.PROMOTION_TEXT3")}
                    </div>
                  </div>
                </div>
                {/* For future use */}
                {/* <div className="numericData row">
              <div className="col-md-4">
                <div className="numericValue">21,01,133</div>
                <div className="numericTitle">
                  {t("GENERAL.MATCHED_DELIVERED")}
                </div>
              </div>
              <div className="col-md-4">
                <div className="numericValue">37,12,187</div>
                <div className="numericTitle">{t("GENERAL.NETWORK_SIZE")}</div>
              </div>
              <div className="col-md-4">
                <div className="numericValue">21,00</div>
                <div className="numericTitle">
                  {t("GENERAL.COMPANIES_HIRING")}
                </div>
              </div>
            </div> */}
              </div>
            </div>
            {/* Similar Jobs Section */}
            <div className="similarJobs">
              <h3>{t("GENERAL.SIMILAR_JOBS")}</h3>
              {similarJobsData?.length > 0 ? (
                similarJobsData?.map((item: any, index: any) => {
                  const jobLocation = [
                    item?.jobCity ? capitalizeFirstLetter(item.jobCity) : "",
                    item?.jobState ? capitalizeFirstLetter(item.jobState) : "",
                    item?.jobCountry
                      ? capitalizeFirstLetter(item.jobCountry)
                      : "",
                  ]
                    .filter(Boolean)
                    .join(", ");
                  return (
                    <div className="jobCard" key={index}>
                      <h4 className="jobCardTitle">
                        {capitalizeFirstLetter(item?.title)}
                      </h4>
                      <div className="jobCardSubDetails">
                        <span>{item?.companyName}</span>
                        <span title={moment(item?.createdAt).format("LLL")}>
                          {getTimeDifference(
                            item?.createdAt,
                            t("GENERAL.MIN(S)"),
                            t("GENERAL.HOUR(S)"),
                            t("GENERAL.DAY(S)"),
                            t("GENERAL.MONTH(S)"),
                            t("GENERAL.YEAR(S)"),
                            t("GENERAL.AGO"),
                            t("GENERAL.JUST_NOW"),
                          ) ?? "-"}
                        </span>
                      </div>
                      <hr />
                      <div className="yearPlace">
                        {item?.minimumExperience && item?.maximumExperience && (
                          <div className="iconText">
                            <div className="icon">
                              <SuitcaseIcon />
                            </div>
                            <span className="text">
                              {item?.minimumExperience}-
                              {item?.maximumExperience} {t("GENERAL.YEAR(S)")}
                            </span>
                          </div>
                        )}
                        <div className="iconText">
                          <div className="icon">
                            <LocationMarkIcon />
                          </div>
                          <span className="text">
                            {jobLocation ? (
                              <span className="text">{jobLocation}</span>
                            ) : (
                              "-"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="bulletSec mt-3 skillsSecWidth">
                        {item?.jobSkills?.map((skill: any) => (
                          <Bullets
                            key={Math.random()}
                            skillName={
                              capitalizeFirstLetter(skill?.skillName) ?? ""
                            }
                          />
                        ))}
                      </div>
                      <div className="applyBtnSec">
                        <AssessmentButton
                          className="lightBtn"
                          onClick={() =>
                            (window.location.href = `${item?.applyLink}`)
                          }
                        >
                          {t("GENERAL.APPLY")}
                        </AssessmentButton>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="noSimilarJobs">{t("GENERAL.NO_SEMILAR_JOB")}</p>
              )}
              {similarJobsData?.length > 0 &&
                totalJobCount > pageSize &&
                currentPage * pageSize < totalJobCount && (
                  <div className="moreJobsSec">
                    <span className="moreJobs" onClick={() => loadMoreJobs()}>
                      {t("GENERAL.MORE_JOBS")}
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className="jobLoader">
          <Loader />
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
};
export default ApplyForJob;
