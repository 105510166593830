const LinkedinIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.02695V20.973C0 22.667 1.33301 24 3.02695 24H20.973C21.8021 24 22.5943 23.6033 23.0994 23.0994C23.6402 22.5586 24 21.8736 24 20.973V3.02695C24 1.33301 22.667 0 20.973 0H3.02695C2.12636 0 1.44141 0.360928 0.900591 0.900591C0.395522 1.40566 0 2.19786 0 3.02695ZM3.24374 5.4047C3.24374 4.25157 4.18123 3.24259 5.33436 3.24259C6.52323 3.24259 7.49647 4.25157 7.49647 5.4047C7.49647 6.59357 6.52323 7.53106 5.33436 7.53106C4.18123 7.53106 3.24374 6.59357 3.24374 5.4047ZM9.04512 20.2154V9.225C9.04512 8.97247 9.29765 8.75683 9.47754 8.75683H12.5402C12.9727 8.75683 12.9727 9.2619 12.9727 9.58593C13.8375 8.72109 14.9549 8.5043 16.108 8.5043C18.9193 8.5043 20.7205 9.83731 20.7205 12.7928V20.2166C20.7205 20.4691 20.5037 20.6848 20.2881 20.6848H17.117C16.8645 20.6848 16.6846 20.4322 16.6846 20.2166V13.5135C16.6846 12.3961 16.3605 11.7838 15.099 11.7838C13.5135 11.7838 13.1168 12.8285 13.1168 14.2342V20.2166C13.1168 20.4691 12.8643 20.6848 12.6117 20.6848H9.47639C9.2965 20.6848 9.04396 20.4322 9.04396 20.2166L9.04512 20.2154ZM3.31524 20.2154V9.225C3.31524 8.97247 3.56777 8.75683 3.74766 8.75683H6.88301C7.17129 8.75683 7.35118 8.93672 7.35118 9.225V20.2154C7.35118 20.468 7.13439 20.6836 6.88301 20.6836H3.74766C3.53087 20.6836 3.31524 20.4311 3.31524 20.2154Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedinIcon;
