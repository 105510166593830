import { ReactNode } from "react";
import "./ButtonWrapper.scss";
interface ButtonWrapper {
  text: string;
  children?: ReactNode;
  onClick: () => void;
  ref?: any; // Make 'ref' optional
}
const ButtonWrapper: React.FC<ButtonWrapper> = ({
  text,
  children,
  onClick,
  ref,
}) => {
  return (
    <div ref={ref}>
      <button onClick={onClick} className="commonBtn">
        <span className="text">{text}</span>
        {children}
      </button>
    </div>
  );
};

export default ButtonWrapper;
