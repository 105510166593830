import { useTranslation } from "react-i18next";
import CheckSystemCaptibility from "../AssessmentDetails/CheckSystemCaptibility";

type MediaPermissions = {
  mic?: boolean | null;
  camera?: boolean | null;
};

interface ISystemCompatibility {
  micPermissions: MediaPermissions;
  cameraPermissions: MediaPermissions;
  internetSpeed: boolean | number | null;
  checkMicPermissions: any;
  checkCameraPermission: any;
  checkInternetSpeed: any;
  internetSpeedIsLoading: boolean;
  retestInternetConnect: any;
  internetSpeedCount: number | null;
  capturedPic: boolean | null;
  captureImageCallBack: any;
  videoRef: any;
  headerVisible?: boolean;
  className?: string;
  microphoneRef?: any;
  cameraRef?: any;
  internetSpeedRef?: any;
  capturePhotoRef?: any;
}
const SystemCompatibility: React.FC<ISystemCompatibility> = ({
  micPermissions,
  cameraPermissions,
  internetSpeed,
  checkMicPermissions,
  checkCameraPermission,
  checkInternetSpeed,
  internetSpeedIsLoading,
  retestInternetConnect,
  internetSpeedCount,
  capturedPic,
  captureImageCallBack,
  videoRef,
  headerVisible = false,
  className = "",
  microphoneRef,
  cameraRef,
  internetSpeedRef,
  capturePhotoRef,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={className}>
        <div className="userImageCapture">
          {headerVisible && (
            <h4 className="mb-3">{t("SYSTEM_COMPATIBILITY_CHECKS")}</h4>
          )}
          <CheckSystemCaptibility
            permission={micPermissions?.mic}
            text={t("GENERAL.MICROPHONE")}
            icon="Microphone"
            checkFn={checkMicPermissions}
            retestFn={checkMicPermissions}
            isLoading={false}
            btnRef={microphoneRef}
          />
          <CheckSystemCaptibility
            permission={cameraPermissions.camera}
            text={t("GENERAL.CAMERA")}
            icon="Camera"
            checkFn={checkCameraPermission}
            retestFn={checkCameraPermission}
            isLoading={false}
            btnRef={cameraRef}
          />
          <CheckSystemCaptibility
            permission={internetSpeed}
            text={t("SUBMIT_ASSESSMENT.INTERNET_SPEED_TEST")}
            icon="InternetSpeed"
            checkFn={checkInternetSpeed}
            isLoading={internetSpeedIsLoading}
            retestFn={retestInternetConnect}
            internetSpeedCount={internetSpeedCount}
            btnRef={internetSpeedRef}
          />
          {headerVisible && (
            <CheckSystemCaptibility
              permission={capturedPic}
              text={t("GENERAL.CAPTURE_IMAGE")}
              icon="CaptureImage"
              checkFn={captureImageCallBack}
              retestFn={captureImageCallBack}
              isLoading={false}
              btnRef={capturePhotoRef}
            />
          )}
        </div>
      </div>
      <div className="imageCapture">
        <video className="captureImage" ref={videoRef} autoPlay muted />
      </div>
    </>
  );
};

export default SystemCompatibility;
