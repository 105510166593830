const LocationMarkIcon = () => {
  return (
    <svg
      width="11"
      height="15"
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 7.03125C4.74054 7.03125 4.125 6.40172 4.125 5.625C4.125 4.84828 4.74054 4.21875 5.5 4.21875C6.25946 4.21875 6.875 4.84828 6.875 5.625C6.875 6.40172 6.25946 7.03125 5.5 7.03125ZM5.5 3.28125C4.23454 3.28125 3.20833 4.33031 3.20833 5.625C3.20833 6.91969 4.23454 7.96875 5.5 7.96875C6.76546 7.96875 7.79167 6.91969 7.79167 5.625C7.79167 4.33031 6.76546 3.28125 5.5 3.28125ZM5.5 13.5937C4.73779 13.598 0.916667 7.58484 0.916667 5.625C0.916667 3.03656 2.96863 0.9375 5.5 0.9375C8.03137 0.9375 10.0833 3.03656 10.0833 5.625C10.0833 7.55859 6.25029 13.598 5.5 13.5937ZM5.5 0C2.46263 0 0 2.51859 0 5.625C0 7.97719 4.58563 15.0052 5.5 15C6.40017 15.0052 11 7.94531 11 5.625C11 2.51859 8.53738 0 5.5 0Z"
        fill="black"
      />
    </svg>
  );
};

export default LocationMarkIcon;
