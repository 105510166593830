import "./Feedback.scss";
import { AssessmentButton, InputField, showToast } from "ui-components";
import SubmitAssessmentHeader from "../../../components/SubmitAssessment/Header/SubmitAssessmentHeader";
import { useEffect, useState } from "react";
import {
  applicantAssessmentFeedback,
  getApplicantDetails,
  updateApplicantProfile,
} from "../../../service/SubmitAssessment/SubmitAssessment";
import CompleteProfile from "../User/CompleteProfile";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setSessionStorageItem } from "../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../utils/SessionStorageMethod/getSessionStorageItem";
import { removeSessionStorageItem } from "../../../utils/SessionStorageMethod/removeSessionStorageItem";

const characterLimit = 1000;
const Feedback = () => {
  const location = useLocation();
  const { assessmentTilte, applicantId, assessmentId } = location.state;
  const [inputText, setInputText] = useState("");
  const [isUserSubmit, setIsUserSubmit] = useState(
    getSessionStorageItem("completeProfileIsuserSubmit") ?? false,
  );
  const [profileUpdate, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [charCount, setCharCount] = useState(0);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  useEffect(() => {
    getApplicantAllDetails();
  }, []);

  // to get the applicant details
  async function getApplicantAllDetails() {
    const feedbackRes = await getApplicantDetails(applicantId ?? "");
    if (feedbackRes?.status === 200) {
      setProfileData((prevState: any) => {
        const updatedData = {
          ...prevState,
          firstName: feedbackRes?.data?.firstName,
          lastName: feedbackRes?.data?.lastName,
          email: feedbackRes?.data?.emailId,
        };
        return updatedData;
      });
      setIsProfileComplete(feedbackRes?.data?.isProfileComplete);
    }
  }

  const trimProfileData = (data: any) => {
    const trimmedData: any = {};
    for (const key in data) {
      if (typeof data[key] === "string") {
        trimmedData[key] = (data[key] as string).trim();
      } else {
        trimmedData[key] = data[key];
      }
    }
    return trimmedData;
  };

  const handleProfileSubmit = async (values: any) => {
    const trimmedProfileUpdate = trimProfileData(profileUpdate);
    trimmedProfileUpdate.applicantId = applicantId;
    trimmedProfileUpdate.password = values?.password;
    const profileSubmitRes = await updateApplicantProfile(trimmedProfileUpdate);
    if (profileSubmitRes?.data?.status === 200) {
      showToast(profileSubmitRes?.data?.message, "success");
      removeSessionStorageItem("completeProfileIsuserSubmit");
      navigate(`/${currentLanguage}/my-assessments`);
      setSessionStorageItem("isFeedbackActive", "false");
      window.close();
    }
  };
  const handleProfileChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const { value, name } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };
  const handleFeedback = async () => {
    const feedback = {
      assessmentId,
      applicantId,
      applicantFeedback: inputText.trim(),
    };
    const feedbackRes = await applicantAssessmentFeedback(feedback);
    if (feedbackRes?.data?.status === 200) {
      showToast(feedbackRes?.data?.message, "success");
      if (!isProfileComplete) {
        setIsUserSubmit(true);
        setSessionStorageItem("completeProfileIsuserSubmit", true);
      } else {
        setSessionStorageItem("isFeedbackActive", "false");
        window.close();
        navigate(`/${currentLanguage}/applicant-login`);
      }
    }
  };

  const handleChange = (id: any, value: any) => {
    setInputText(value);
    setCharCount(value.length);
  };

  return (
    <>
      <div className={`${isUserSubmit ? "userActive" : ""}`}>
        <SubmitAssessmentHeader hideHeaderWalkthrough={true} />
        <div className="feedbackSection">
          <div className="feedbackHead">
            <h1 className="feedbackTitle">{t("SUBMIT_ASSESSMENT_FEEDBACK")}</h1>
            <h4 className="feedbackTitle">{assessmentTilte}</h4>
            <h6>{t("SUBMIT_ASSESSMENT_FEEDBACK_DESCRIPTION")}</h6>
          </div>
          <div className="feedbackContent">
            <InputField
              additionalProps={{
                as: "textarea",
                rows: 8,
                style: { resize: "none" },
              }}
              value={inputText}
              onChange={(e) => handleChange(null, e)}
            />
            <div
              className={
                inputText.length > characterLimit
                  ? "limitAccessCssOver limitAccess"
                  : "limitAccess"
              }
            >
              <p className={"charLimitMsg"}>
                {t("GENERAL.MUST_BE_LESS_THAN")} {characterLimit}{" "}
                {t("GENERAL.CHARACTERS")}
              </p>
              {charCount > 0 && (
                <p className="charLimit">
                  {charCount <= characterLimit
                    ? characterLimit - charCount
                    : `-${charCount - characterLimit}`}
                  &nbsp;
                  {t("SUBMIT_ASSESSMENT.TEXT_EDITOR_LIMIT")}
                </p>
              )}
            </div>
          </div>

          <div className="feedbackBtn mb-3">
            {inputText.length > characterLimit ||
            inputText?.trim().length === 0 ? (
              <button className="submitDisable">{t("GENERAL.SUBMIT")}</button>
            ) : (
              <AssessmentButton onClick={() => handleFeedback()}>
                {t("GENERAL.SUBMIT")}
              </AssessmentButton>
            )}
            {isProfileComplete ? (
              <AssessmentButton
                onClick={() => {
                  window.close();
                  navigate(`/${currentLanguage}/my-assessments`);
                }}
              >
                {t("GENERAL.SKIP")}
              </AssessmentButton>
            ) : (
              <AssessmentButton onClick={() => setIsUserSubmit(true)}>
                {t("GENERAL.SKIP")}
              </AssessmentButton>
            )}
          </div>
        </div>
        {!isProfileComplete && isUserSubmit && (
          <CompleteProfile
            handleProfileSubmit={handleProfileSubmit}
            handleProfileChange={handleProfileChange}
            profileUpdate={profileUpdate}
          />
        )}
      </div>
    </>
  );
};

export default Feedback;
