import { uploadMultiPart, uploadVideoMultiPart } from "smartassess-api-lib";

export const videoUploader = async (
  blob: any,
  path?: string,
  fileExtension?: string,
) => {
  const fileData = [];
  fileData.push(blob);

  const jsonData = {
    file: fileData,
    path,
    startUploadEndPoint: "s3Upload/startUpload",
    getPresignedEndPoint: "s3Upload/generatePresigned",
    completeUploadEndPoint: "s3Upload/completeUpload",
    fileExtension: fileExtension ? fileExtension : "webm",
  };
  const imageData = await uploadVideoMultiPart(jsonData);
  return imageData?.data?.data;
};

export const ImageUploader = async (e: any, path?: string) => {
  const fileData = [];
  for (let i = 0; i < e.target.files.length; i += 1) {
    fileData.push(e.target.files[i]);
  }
  const jsonData = {
    file: fileData,
    path: path,
    startUploadEndPoint: "s3Upload/startUpload",
    getPresignedEndPoint: "s3Upload/generatePresigned",
    completeUploadEndPoint: "s3Upload/completeUpload",
  };
  const imageData = await uploadMultiPart(jsonData);
  e.target.value = "";
  return imageData?.data?.data;
};

// This function work for multifile upload.
export const MultiFileUploader = async (e: any, path?: string) => {
  const fileData = [e];
  const jsonData = {
    file: fileData,
    path: path,
    startUploadEndPoint: "s3Upload/startUpload",
    getPresignedEndPoint: "s3Upload/generatePresigned",
    completeUploadEndPoint: "s3Upload/completeUpload",
  };
  const imageData = await uploadMultiPart(jsonData);
  return imageData?.data?.data;
};
