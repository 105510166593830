import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./SubmitAssessmentHeader.scss";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import i18n from "../../../i18nConfig";
import LogoTagline from "../../../utils/Images/LogoTagline";
import { APIClient } from "smartassess-api-lib";
import { useOnlineStatus } from "ui-components";
import Walkthrough from "../../WalkThrough/Walkthrough";
interface ListingHeader {
  children?: ReactNode;
  isMenuHide?: any;
  setIsHeaderWalkthroughDone?: any;
  hideHeaderWalkthrough?: boolean;
  stepsArray?: any;
  overlayHeight?: any;
  overlayTop?: any;
  overlayLeft?: any;
  overlayWidth?: any;
  isNavbarVisible?: boolean
}
const SubmitAssessmentHeader: React.FC<ListingHeader> = ({
  children,
  isMenuHide,
  setIsHeaderWalkthroughDone,
  hideHeaderWalkthrough,
  stepsArray,
  overlayHeight,
  overlayTop,
  overlayWidth,
  overlayLeft,
  isNavbarVisible = true
}) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [navToggle, setNavToggle] = useState(false);
  const popoverRef: any = useRef(null);
  const isOnline = useOnlineStatus();
  const iconRef: any = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const email = localStorage.getItem("emailId");
  const currentLanguage = i18n.language;
  const [isWalkthroughActive, setIsWalkthroughActive] = useState(true);

  const togglePopover = () => {
    setPopoverVisible((prevVisible) => !prevVisible);
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setPopoverVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleLogout = () => {
    localStorage.removeItem("applicantToken");
    localStorage.removeItem("applicantId");
    localStorage.removeItem("emailId");
    APIClient.setApiToken("");
    navigate(`/${currentLanguage}/applicant-login`);
  };
  const assessmentTitleRef = useRef<HTMLLIElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const drawerIconRef = useRef<HTMLButtonElement>(null);
  const requirementRef = useRef<any>(null);
  const walkthroughSteps = stepsArray
    ? stepsArray
    : [
      {
        content: "Here you can view all assessments.",
        ref: assessmentTitleRef,
      },
      { content: "See available jobs here", ref: requirementRef },
      { content: "Access your profile and credits here.", ref: profileRef },
    ];
  const handleWalkthroughComplete = () => {
    setIsWalkthroughActive(false);
    setIsHeaderWalkthroughDone(true);
  };
  return (
    <>
      <div className="internet-banner">
        <div className={`banner ${!isOnline ? "show zIndexTop" : ""}`}>
          {t("GENERAL.INTERNET_CONNECTION")}
        </div>
      </div>
      {!hideHeaderWalkthrough && isWalkthroughActive && (
        <Walkthrough
          steps={walkthroughSteps}
          onComplete={handleWalkthroughComplete}
          hasPreviousButton={true}
          showSkipNextTogether={true}
          offsetTop={overlayTop ?? -5}
          offsetLeft={overlayLeft ?? -5}
          offsetWidth={overlayWidth ?? +12}
          offsetHeight={overlayHeight ?? +10}
        />
      )}

      <nav
        className={`navbar navbar-expand-lg header ${navToggle && "navToggle"} ${!isOnline && "main-content"}`}
      >
        <div className="container-fluid logo-link">
          <div className="logo cursorPointer" onClick={() => navigate("/")}>
            <LogoTagline />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              setTimeout(() => setNavToggle(!navToggle), 40);
            }}
            ref={drawerIconRef}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {isNavbarVisible && (
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 link">
                <li className="nav-item" ref={assessmentTitleRef}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link addBottomBorder" : "nav-link"
                    }
                    to={`/${currentLanguage}/my-assessments`}
                  >
                    {t("SUBMIT_ASSESSMENT_MY_ASSESSMENT")}
                  </NavLink>
                </li>
                <li className="nav-item" ref={requirementRef}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link addBottomBorder" : "nav-link"
                    }
                    to={`/${currentLanguage}/requirements`}
                  >
                    {t("GENERAL.REQUIREMENTS")}
                  </NavLink>
                </li>
              </ul>
            )}
            <div className="Btn" ref={profileRef}>
              {children}
              {!isMenuHide && (
                <div className="profile-settings">
                  <div ref={iconRef} onClick={togglePopover}>
                    <div className="logo-place">
                      {email?.charAt(0).toLocaleUpperCase()}
                    </div>
                  </div>
                  {isPopoverVisible && (
                    <div className="popover" ref={popoverRef}>
                      <div className="popover-header">
                        <div className="logo-name-sec">
                          <div className="profile-icon company-logo">
                            <span>{email?.charAt(0).toLocaleUpperCase()}</span>
                          </div>
                          <div className="company-name">
                            <strong>{"-"}</strong>
                            <div className="email-date-section">
                              <span>{email ? email : "-"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="popover-body">
                        <div
                          className="popover-item"
                          onClick={() => navigate("/")}
                        >
                          {t("SUBMIT_ASSESSMENT_MY_ASSESSMENT")}
                        </div>
                        <div
                          className="popover-item"
                          onClick={() =>
                            navigate(`/${currentLanguage}/requirements`)
                          }
                        >
                          {t("GENERAL.REQUIREMENTS")}
                        </div>
                      </div>
                      <div className="popover-footer">
                        <hr />
                        <div className="popover-item" onClick={handleLogout}>
                          {t("GENERAL.LOGOUT")}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default SubmitAssessmentHeader;
