import React, { useEffect, useState } from "react";
import "./Walkthrough.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface WalkthroughProps {
  steps: { content: string; ref: React.RefObject<HTMLElement> }[];
  onComplete: () => void;
  hasPreviousButton?: boolean;
  showSkipNextTogether?: boolean;
  offsetTop?: number;
  offsetLeft?: number;
  offsetWidth?: number;
  offsetHeight?: number;
}

const Walkthrough: React.FC<WalkthroughProps> = ({
  steps,
  onComplete,
  hasPreviousButton = false,
  showSkipNextTogether = false,
  offsetTop = 0,
  offsetLeft = 0,
  offsetWidth = 0,
  offsetHeight = 0,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();
  const pathName = location?.pathname.split("/");
  const isSubmitassessment = pathName.includes("submitassessment");
  const handleNext = () => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      onComplete();
    }
  };

  const handlePrevious = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const handleSkip = () => onComplete();

  const currentStep = steps[currentStepIndex];

  useEffect(() => {
    const currentElement = currentStep.ref.current;
    if (!currentElement) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          // Scroll to make the current step visible
          currentElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      },
      {
        root: null, // Use the viewport as the container
        threshold: 0.1, // Consider the element visible when 10% is in view
      },
    );

    observer.observe(currentElement);

    return () => {
      observer.disconnect();
    };
  }, [currentStepIndex]);

  return (
    <div className="walkthrough-container">
      <div className="walkthrough-overlay" />
      {currentStep.ref.current && (
        <div
          className={`highlighted-elements ${!isSubmitassessment && "assessmentDetail-element"}`}
          style={{
            top: `${currentStep.ref.current.getBoundingClientRect().top + window.scrollY + offsetTop}px`,
            left: `${currentStep.ref.current.getBoundingClientRect().left + offsetLeft}px`,
            width: `${currentStep.ref.current.getBoundingClientRect().width + offsetWidth}px`,
            height: `${currentStep.ref.current.getBoundingClientRect().height + offsetHeight}px`,
            position: "absolute",
          }}
        />
      )}
      <div className="walkthrough-footer">
        <h6 style={{ color: "white", fontWeight: "bold" }}>
          {currentStep.content}
        </h6>
        <div className="foot">
          {showSkipNextTogether && currentStepIndex < steps.length - 1 && (
            <button onClick={handleSkip}>{t("GENERAL.SKIP")}</button>
          )}
          {hasPreviousButton && currentStepIndex > 0 && (
            <button onClick={handlePrevious}>{t("GENERAL.PREVIOUS")}</button>
          )}
          <div className="walkthrough-pagination">
            {steps.map((_, index) => (
              <span
                key={index}
                className={`pagination-circle ${
                  index === currentStepIndex ? "active" : ""
                }`}
              />
            ))}
          </div>
          <button onClick={handleNext}>
            {currentStepIndex === steps.length - 1 ? "Finish" : "Next"}
          </button>
          {!showSkipNextTogether && currentStepIndex < steps.length - 1 && (
            <button onClick={handleSkip}>{t("GENERAL.SKIP")}</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Walkthrough;
