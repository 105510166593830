import { useTranslation } from "react-i18next";
import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import SubmitAssessmentHeader from "../../../components/SubmitAssessment/Header/SubmitAssessmentHeader";

const Requirements = () => {
  const { t } = useTranslation();
  return (
    <div>
      <SubmitAssessmentHeader hideHeaderWalkthrough={true} />
      <ComingSoon title={t("GENERAL.REQUIREMENTS")} isBackArrowVisible={true} />
    </div>
  );
};
export default Requirements;
