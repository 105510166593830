import SubmitAssessmentHeader from "../../../components/SubmitAssessment/Header/SubmitAssessmentHeader";
import SubmitAssessmentCalender from "../../../utils/Images/SubmitAssessmentCalender";
import ButtonWrapper from "../CommonBtn/ButtonWrapper";
import "./QuestionList.scss";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer/Footer";
import { useEffect, useRef, useState } from "react";
import InstructionModal from "../InstructionModal/InstructionModal";
import { useLocation, useNavigate } from "react-router-dom";
import {
  assessmentQuestionReport,
  getApplicantAssessmentQuestion,
  submitAndSkipApplicantAssessmentAnswer,
  updateAssessmentApplicantQuestionStatus,
} from "../../../service/SubmitAssessment/SubmitAssessment";
import QuestionProgressBar from "./QuestionProgressBar";
import AnswerQuestion from "./AnswerQuestion";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  convertToText,
  exitFullScreen,
  formatTime,
  updateProgressBarAndColors,
} from "../../../utils/Common/Constant";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { AssessmentButton, showToast } from "ui-components";
import SystemCompatibility from "../SystemCompatibility/SystemCompatibility";
import DeviceDetector from "../DeviceDetector/DeviceDetector";
import { setSessionStorageItem } from "../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../utils/SessionStorageMethod/getSessionStorageItem";
import { removeSessionStorageItem } from "../../../utils/SessionStorageMethod/removeSessionStorageItem";
import ConfirmPopup from "./ConfirmPopup/ConfirmPopup";
import AddReport from "./AddReport";
import OrdinalShift from "../../../components/OrdinalShift/OrdinalShift";
import Timer from "../../../utils/Images/Timer";
import PlayVoice from "../../../components/PlayVoices/PlayVoice";
import Walkthrough from "../../../components/WalkThrough/Walkthrough";
const charLimit = 1000;
type MediaPermissions = {
  mic?: boolean | null;
  camera?: boolean | null;
};
const QuestionList = () => {
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const location = useLocation();
  const navigate = useNavigate();
  const { transcript, browserSupportsSpeechRecognition, resetTranscript } =
    useSpeechRecognition();
  const { assessmentDetailsData, instructions } = location.state;
  const [questionData, setQuestionData] = useState<any>();
  const questionSet = Array(assessmentDetailsData?.totalQuestions).fill(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isTimerComplete, setIsTimerComplete] = useState(false);
  const [singleTypeValue, setSingleTypeValue] = useState<any>();
  const [multipleTypeValue, setMultipleTypeValue] = useState([]);
  const [essayTypeValue, setEssayTypeValue] = useState("");
  const [subTimer, setSubTimer] = useState<number>(1);
  const [apiError, setApiError] = useState<boolean>(false);
  const [subTimerReference, setSubTimerReference] = useState<number>(0);
  const [subQuestionColors, setSubQuestionColors] = useState<any>([]);
  const [progressbar, setProgressBar] = useState({
    progressbar: 0,
    index: 0,
  });
  const [show, setShow] = useState(false);
  // check for camera, mic, internet
  const [isCheckCompatibilities, setIsCheckCompatibilities] = useState(false);
  const videoContinueRef = useRef<HTMLVideoElement>(null);
  const [micPermissions, setMicPermissions] = useState<MediaPermissions>({
    mic: null,
  });
  const [cameraPermissions, setCameraPermissions] = useState<MediaPermissions>({
    camera: null,
  });
  const [internetSpeed, setInternetSpeed] = useState<boolean | number | null>(
    null,
  );
  const [internetSpeedCount, setInternetSpeedCount] = useState<number | null>(
    null,
  );
  const [internetSpeedIsLoading, setInternetSpeedIsLoading] = useState(false);
  const [isResumeDisable, setIsResumeDisable] = useState(false);
  const [isTextToSpeechFinished, setIsTextToSpeechFinished] = useState(false);
  const [transcriptEnabled, setTranscriptEnabled] = useState(false);
  const [isBlinking, setIsBlinking] = useState(true);
  const [codingAnsTemplate, setCodingAnsTemplate] = useState("");
  const [testCases, setTestCases] = useState([]);
  const [codeOutPut, setCodeOutput] = useState("");
  const [isStatus, setIsStatus] = useState("");
  const [startQuestionLoader, setStartQuestionLoader] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [isTabSwitchedBack, setIsTabSwitchedBack] = useState(false);
  const [isDefferedConfirm, setIsDefferedConfirm] = useState(false);
  const [addReport, setAddReport] = useState("");
  const [isReport, setIsReport] = useState(false);
  const [isLastReportQuestion, SetIsLastReportQuestion] = useState(false);
  const [isAbortedPopup, SetIsAbortedPopup] = useState(false);
  const [isCodingQuestionEdited, SetIsCodingQuestionEdited] = useState(false);
  const [isStartRecording, SetIsStartRecording] = useState(false);
  const [isWalkthroughActive, setIsWalkthroughActive] = useState(true);
  // const [abortedTimer, setAbortedTimer] = useState(5);
  const isFirefox =
    typeof navigator !== "undefined" &&
    navigator.userAgent.toLowerCase().includes("firefox");
  const [isPlaying, setIsPlaying] = useState(false);
  const handleAudioEnd = () => {
    setIsTextToSpeechFinished(true);
    SetIsStartRecording(true);
    setTranscriptEnabled(true);
    setIsPlaying(false);
    localStorage.removeItem("firstTimeWalkThrough");

    // You can add more logic here, like enabling a button, notifying the user, etc.
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        setIsTabSwitchedBack(true);
      }
    };

    // const handleWindowBlur = () => {
    //   setIsTabSwitchedBack(true);
    // };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // window.addEventListener("blur", handleWindowBlur);
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      // window.removeEventListener("blur", handleWindowBlur);
    };
  }, []);

  // this useeffecr handles the disabling of next question
  useEffect(() => {
    handleDisableNext(questionData?.type);
  }, [
    singleTypeValue,
    multipleTypeValue,
    essayTypeValue,
    transcript,
    isCodingQuestionEdited,
  ]);
  const handleDisableNext = (type: string) => {
    let disable = true; // Initially, next button should be disabled
    if (type === "Single") {
      disable =
        singleTypeValue && Object.keys(singleTypeValue).length !== 0
          ? false
          : true;
    } else if (type === "Multiple") {
      disable = multipleTypeValue.length > 0 ? false : true;
    } else if (type === "Essay") {
      disable = essayTypeValue.trim().length > 0 ? false : true;
    } else if (type === "Video") {
      disable = transcript.trim().length > 0 ? false : true;
    } else if (type === "Coding") {
      disable = isCodingQuestionEdited ? false : true;
    }
    // Update the disableNext state
    setDisableNext(startQuestionLoader ? true : disable);
    return disable;
  };
  // this useEffect executes when question changes
  useEffect(() => {
    setSingleTypeValue(null);
    setMultipleTypeValue([]);
    setEssayTypeValue("");
    resetTranscript();
    setDisableNext(true);
  }, [currentQuestionIndex]);
  useEffect(() => {
    localStorage.removeItem("firstTimeWalkThrough")
  }, [])
  useEffect(() => {
    if (localStorage.getItem("firstTimeWalkThrough")) {
      setIsPlaying(true);
    } else {
      handleApplicantAssessmentQuestion();
    }
  }, [isWalkthroughActive]);
  //timer for cbt question
  useEffect(() => {
    const interval = setInterval(() => {
      if (isTextToSpeechFinished) {
        if (!isCheckCompatibilities && !isTabSwitchedBack && !isAbortedPopup) {
          if (subTimer === 16) {
            setIsBlinking(true);
          }
          setSubTimer((prevTimer: number) => {
            if (prevTimer > 0) {
              return prevTimer - 1; // Decrement the timer by 1 second
            } else {
              clearInterval(interval); // Stop the interval when timer reaches 0
              resetTranscript();
              return 0;
            }
          });
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [
    subTimer,
    isCheckCompatibilities,
    isTextToSpeechFinished,
    isTabSwitchedBack,
  ]);
  // call automatically handleApplicantAssessmentQuestion when subtimer reaches 0
  useEffect(() => {
    if (!isTimerComplete) {
      if (subTimer === 0 && !apiError) {
        if (isFirefox && questionData?.type === "Video") {
          setSessionStorageItem("isStatusUpdate", "answered");
        } else {
          const checkHaveAnswered = handleDisableNext(questionData?.type);
          if (!checkHaveAnswered) {
            setSessionStorageItem("isStatusUpdate", "answered");
          } else {
            setSessionStorageItem("isStatusUpdate", "skipped");
          }
        }
        handleAnswer();
      }
    }
  }, [subTimer, startQuestionLoader, isStatus, apiError]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleAnswer = () => {
    localStorage.removeItem("firstTimeWalkThrough");
    if (subTimer >= 0) {
      setIsTimerComplete(true);
      setSessionStorageItem("timetaken", subTimer);
      setSubTimer(0);
    }
  };
  const handleAbortedModalSave = () => {
    SetIsAbortedPopup(false);
    showToast(t("GENERAL.ASSESSMENT_TERMINATED"), "success");
    // Check if the document is in fullscreen mode and exit if true
    exitFullScreen();
    navigate(`/${currentLanguage}/feedback`, {
      state: {
        assessmentTilte: assessmentDetailsData?.title,
        applicantId: assessmentDetailsData?.applicantId,
        assessmentId: assessmentDetailsData?.assessmentId,
      },
    });
  };
  const handleTabChangeModalSave = () => {
    setIsTabSwitchedBack(false);
    handleApplicantAssessmentQuestion();
  };
  // to get next question and pass previous question answer to submitAndSkipApplicantAssessmentAnswer
  const handleNext = async (videoURL: string) => {
    const isStatusUpdate = getSessionStorageItem("isStatusUpdate");
    const submitData = {
      applicantId: assessmentDetailsData?.applicantId,
      assessmentId: assessmentDetailsData?.assessmentId,
      assessmentApplicantQuestionId:
        questionData?.assessmentApplicantQuestionId,
      questionId: questionData?.questionId,
      questionType: questionData?.types ?? questionData?.type,
      totalResponseTime: subTimerReference - getSessionStorageItem("timetaken"),
      response:
        isStatusUpdate === "answered"
          ? handleTypeAnswer(questionData?.type, videoURL)
          : "",
      isFinalSubmit:
        assessmentDetailsData?.totalQuestions === currentQuestionIndex &&
          isLastReportQuestion
          ? false
          : assessmentDetailsData?.totalQuestions === currentQuestionIndex
            ? true
            : false,

      status: isStatusUpdate,
    };
    if (isStatusUpdate && isStatusUpdate?.length) {
      const submitRes =
        await submitAndSkipApplicantAssessmentAnswer(submitData);
      if (submitRes?.status === 200) {
        removeSessionStorageItem("timetaken");
        if (submitData?.isFinalSubmit) {
          showToast(
            `${t("SUBMIT_ASSESSMENT_ASSESSMENT_SUBMITTED")}`,
            "success",
          );
          // Check if the document is in fullscreen mode and exit if true
          if (document.fullscreenElement) {
            document.exitFullscreen().catch(() => {
              alert(t("ERROR.EXIT_FULL_SCREEN_MODE"));
            });
          }
          setSessionStorageItem("isFeedbackActive", "true");
          navigate(`/${currentLanguage}/feedback`, {
            state: {
              assessmentTilte: assessmentDetailsData?.title,
              applicantId: assessmentDetailsData?.applicantId,
              assessmentId: assessmentDetailsData?.assessmentId,
            },
          });
        } else {
          resetGivenAns();
          removeSessionStorageItem("isStatusUpdate");
          handleApplicantAssessmentQuestion();
        }
      }
    }
  };
  const handleTypeAnswer = (type: string, videoUrl: string) => {
    switch (type) {
      case "Single":
        return { answer: [singleTypeValue], answerVideoUrl: videoUrl };
      case "Multiple":
        return { answer: multipleTypeValue, answerVideoUrl: videoUrl };
      case "Essay":
        return {
          answer:
            essayTypeValue.trim()?.length > charLimit
              ? essayTypeValue.trim().slice(0, charLimit)
              : essayTypeValue.trim(),
          answerVideoUrl: videoUrl,
        };
      case "Video":
        return { answer: transcript, answerVideoUrl: videoUrl };
      case "Coding":
        return {
          answer: btoa(codingAnsTemplate),
          answerVideoUrl: videoUrl,
          meta: testCases,
        };
    }
  };

  const resetGivenAns = () => {
    setSingleTypeValue(null);
    setMultipleTypeValue([]);
    setEssayTypeValue("");
  };
  const handleApplicantAssessmentQuestion = async () => {
    setIsReport(false);
    SetIsLastReportQuestion(false);
    setAddReport("");
    resetTranscript();
    setTranscriptEnabled(false);
    setIsBlinking(false);
    setIsTextToSpeechFinished(false);
    setIsTimerComplete(false);
    setTestCases([]);
    setCodeOutput("");
    SetIsCodingQuestionEdited(false);
    SetIsStartRecording(false);
    const isFeedbackActive = getSessionStorageItem("isFeedbackActive");
    if (isFeedbackActive !== "true") {
      const questionData = await getApplicantAssessmentQuestion(
        assessmentDetailsData?.applicantId,
        assessmentDetailsData?.assessmentId,
      );

      if (questionData?.status === 200 && questionData?.data) {
        // Introduce a delay of 1 second
        await new Promise((resolve) => setTimeout(resolve, 1000));

        await updateAssessmentApplicantQuestionStatus({
          assessmentApplicantQuestionId:
            questionData?.data?.assessmentApplicantQuestionId,
          status: "displayed",
        });
        setQuestionData(questionData?.data);
        setSubTimer(questionData?.data?.timeLimit * 60);
        setApiError(true);
        setStartQuestionLoader(false);
        setSubTimerReference(questionData?.data?.timeLimit * 60);
        setCurrentQuestionIndex(questionData?.data?.currentQuestion ?? 1);
        !localStorage.getItem("questionsScreenWalkthrough") &&
          setIsPlaying(true);
        if (questionData?.data?.metaData?.template) {
          setCodingAnsTemplate(
            convertToText(questionData?.data?.metaData?.template),
          );
        }
        setSessionStorageItem(
          "currentQuestionId",
          questionData?.data?.assessmentApplicantQuestionId,
        );
        updateProgressBarAndColors(
          questionSet,
          currentQuestionIndex,
          questionData,
          assessmentDetailsData,
          setProgressBar,
          setSubQuestionColors,
          setApiError,
        );
      } else {
        setApiError(true);

        if (document.fullscreenElement) {
          document.exitFullscreen().catch(() => {
            alert(t("ERROR.EXIT_FULL_SCREEN_MODE"));
          });
        }

        setSessionStorageItem("isFeedbackActive", "true");
        navigate(`/${currentLanguage}/feedback`, {
          state: {
            assessmentTilte: assessmentDetailsData?.title,
            applicantId: assessmentDetailsData?.applicantId,
            assessmentId: assessmentDetailsData?.assessmentId,
          },
        });
      }
    } else {
      setSessionStorageItem("isFeedbackActive", "true");
      navigate(`/${currentLanguage}/feedback`, {
        state: {
          assessmentTilte: assessmentDetailsData?.title,
          applicantId: assessmentDetailsData?.applicantId,
          assessmentId: assessmentDetailsData?.assessmentId,
        },
      });
    }
  };

  // check Internet Speed
  const checkInternetSpeed = async () => {
    try {
      const numTests: number = 10; // Number of tests to perform
      const setInternetSpeedf = (speedInMbps: number | boolean) => {
        setInternetSpeedIsLoading(false);
        if (typeof speedInMbps === "number") {
          setInternetSpeed(true);
          setInternetSpeedCount(parseFloat(speedInMbps.toFixed(2)));
        } else {
          setInternetSpeed(false);
        }
      };

      const testSpeed = async () => {
        try {
          setInternetSpeedIsLoading(true);
          const imageApi: string = "https://picsum.photos/200/300";
          let totalBits: number = 0;

          for (let i = 0; i < numTests; i++) {
            const startTime = Date.now();
            await fetch(imageApi).then((response) => {
              const endTime = Date.now();
              const imageSize = parseInt(
                response.headers.get("content-length") || "0",
              );
              const timeDuration = (endTime - startTime) / 1000; // in seconds
              const loadedBits = imageSize * 8;
              const speedInBps = loadedBits / timeDuration; // bits per second
              totalBits += speedInBps;
            });
          }

          const averageSpeedInMbps =
            (totalBits / (numTests * 1024 * 1024)) * numTests; // Mbps
          setInternetSpeedf(averageSpeedInMbps);
          setInternetSpeed(true);
        } catch (error) {
          console.error("Error testing internet speed:", error);
          setInternetSpeed(false);
          setInternetSpeedIsLoading(false);
        }
      };

      // Initial function to start tests
      const init = async () => {
        await testSpeed();
      };
      init();
    } catch (error) {
      console.error("Error checking internet speed:", error);
      setInternetSpeed(false);
      setInternetSpeedIsLoading(false);
    }
  };

  // retest Internet Connect
  const retestInternetConnect = () => {
    setInternetSpeed(null);
    setInternetSpeedCount(null);
    checkInternetSpeed();
    resetTranscript();
  };

  // handle device changes
  const handleDeviceChange = ({
    cameraCallBack,
    microphoneCallBack,
    internetCallBack,
  }: any) => {
    if (cameraPermissions.camera !== cameraCallBack) {
      setCameraPermissions({
        camera: cameraCallBack,
      });
    }

    if (micPermissions.mic !== microphoneCallBack) {
      setMicPermissions({
        mic: microphoneCallBack,
      });
    }

    if (internetSpeed !== internetCallBack) {
      if (navigator.onLine) {
        setInternetSpeed(true);
        checkInternetSpeed();
      } else {
        setInternetSpeed(false);
        setInternetSpeedCount(null);
      }
    }

    if (
      cameraCallBack &&
      microphoneCallBack &&
      internetCallBack &&
      internetSpeedCount != null
    ) {
      setIsResumeDisable(false);
      setTranscriptEnabled(true);
    } else if (
      cameraCallBack === false ||
      microphoneCallBack === false ||
      internetCallBack === false
    ) {
      setIsCheckCompatibilities(true);
      setIsResumeDisable(true);
      setTranscriptEnabled(false);
    }
  };

  // handle Modal Save function
  const handleSystemModalSave = () => {
    setIsCheckCompatibilities(false);
    resetGivenAns();
    handleApplicantAssessmentQuestion();
  };
  const handleSubmitAnswer = (imagePathUrlNew: string) => {
    handleNext(imagePathUrlNew);
  };
  const handleSubmitReport = async () => {
    const reportData = {
      assessmentId: assessmentDetailsData?.assessmentId,
      questionId: questionData?.questionId,
      assessmentApplicantQuestionId:
        questionData?.assessmentApplicantQuestionId,
      question: questionData?.question?.question || questionData?.question,
      questionType: questionData?.type,
      comment: addReport,
      reportedById: assessmentDetailsData?.applicantId,
    };
    const reportRes = await assessmentQuestionReport(reportData);
    if (reportRes?.status === 200) {
      if (reportRes?.data?.data?.reportCountExceed) {
        SetIsAbortedPopup(true);
      } else {
        SetIsLastReportQuestion(true);
        showToast(reportRes?.data?.customMessage, "success");
        setAddReport("");
        setIsReport(!isReport);
        setSessionStorageItem("isReport", true);
        setSessionStorageItem("isStatusUpdate", "reported");
        handleAnswer();
      }
    }
  };
  const handleExitForNow = () => {
    exitFullScreen();
    window.history.back();
    window.close();
    setIsDefferedConfirm(false);
  };
  const [isHeaderWalkthroughDone, setIsHeaderWalkthroughDone] = useState(false);
  const handleWalkthroughComplete = () => {
    setIsWalkthroughActive(false);
    setIsHeaderWalkthroughDone(true);
    localStorage.setItem("firstTimeWalkThrough", "done");
    localStorage.removeItem("questionsScreenWalkthrough");
  };
  const skillNameRef: any = useRef(null);
  const questionTextRef: any = useRef(null);
  const activeTimerRef: any = useRef(null);
  const reportBtnRef: any = useRef(null);
  const skipBtnRef: any = useRef(null);
  const nextQuestRef: any = useRef(null);
  const instructionsRef: any = useRef(null);
  const exitInterviewRef: any = useRef(null);
  const micImgRef: any = useRef(null);
  const walkthroughSteps =
    (questionData?.types ?? questionData?.type === "Video")
      ? [
        { content: "This is skill name.", ref: skillNameRef },
        { content: "This is question.", ref: questionTextRef },
        { content: "This is time to give answer.", ref: activeTimerRef },
        {
          content: "This mic indicate that verbal questions",
          ref: micImgRef,
        },
        {
          content: "If there is any problem in question report from here.",
          ref: reportBtnRef,
        },
        { content: "Button to skip the question.", ref: skipBtnRef },
        { content: "Button to get next question.", ref: nextQuestRef },
      ]
      : [
        { content: "This is skill name.", ref: skillNameRef },
        { content: "This is question.", ref: questionTextRef },
        { content: "This is time to give answer.", ref: activeTimerRef },
        {
          content: "If there is any problem in question report from here.",
          ref: reportBtnRef,
        },
        { content: "Button to skip the question.", ref: skipBtnRef },
        { content: "Button to get next question.", ref: nextQuestRef },
      ];
  return (
    <>
      <div className={`questionList ${isBlinking ? "countDown" : ""}`}>
        <SubmitAssessmentHeader
          isMenuHide={true}
          setIsHeaderWalkthroughDone={setIsHeaderWalkthroughDone}
          stepsArray={[
            { content: "Read Instructions from here", ref: instructionsRef },
            { content: "To exit from the interview", ref: exitInterviewRef },
          ]}
          overlayHeight={20}
          overlayTop={-10}
          overlayWidth={30}
          overlayLeft={-15}
          hideHeaderWalkthrough={
            !localStorage.getItem("questionsScreenWalkthrough")
          }
          isNavbarVisible={false}
        >
          <div className="header-items">
            <span className="attempt-text">
              {/* Convert this text into multiple languages when reviewing text */}
              This is your
              <span className="highlight">
                <OrdinalShift number={questionData?.totalAttempts} />{" "}
                &nbsp;attempt
              </span>
            </span>
            {isHeaderWalkthroughDone && isWalkthroughActive && (
              <Walkthrough
                steps={walkthroughSteps}
                onComplete={handleWalkthroughComplete}
                hasPreviousButton={true}
                showSkipNextTogether={true}
                offsetTop={-5}
                offsetLeft={-5}
                offsetWidth={+12}
                offsetHeight={+10}
              />
            )}
            <ButtonWrapper text="" onClick={() => { }}>
              <div className="instrunctioPopup" ref={instructionsRef}>
                <button className="instructionBtn" onClick={handleShow}>
                  {t("GENERAL.INSTRUCTIONS")}
                </button>
                <InstructionModal
                  show={show}
                  handleClose={handleClose}
                  handleShow={handleShow}
                  instruction={instructions}
                />
                <SubmitAssessmentCalender
                  classname="calender"
                  width="17"
                  height="20"
                />
              </div>
            </ButtonWrapper>
            <div ref={exitInterviewRef}>
              <AssessmentButton
                className="lightBtn logoutBtn"
                onClick={() => {
                  setIsDefferedConfirm(true);
                }}
              >
                {t("GENERAL.DEFFERED")}
              </AssessmentButton>
            </div>
          </div>
        </SubmitAssessmentHeader>
        {isPlaying && (
          <PlayVoice
            qNo={currentQuestionIndex}
            qType={questionData?.type?.toLowerCase()}
            qMins={questionData?.timeLimit}
            onAudioEnd={handleAudioEnd}
            isPlaying={isPlaying}
          />
        )}
        <div className="container-fluid mt-1">
          <div className="title">
            <div className="d-flex gap-2 w-100 justify-content-between">
              <h5 className="assessmenttitle">
                {assessmentDetailsData?.title}{" "}
              </h5>
              {(questionData?.types ?? questionData?.type === "Coding") && (
                <div
                  className={`${isBlinking ? "globalCodingTimeBlink" : "globalCodingTime"}`}
                  ref={activeTimerRef}
                >
                  <div>
                    <Timer
                      color={isBlinking ? "red" : "#5A5A5A"}
                      width="19"
                      height="22"
                    />
                  </div>
                  <p
                    className={` ${isBlinking ? "blink" : "timerCoding mt-1"}`}
                  >
                    {formatTime(subTimer)} sec
                  </p>
                </div>
              )}
            </div>
          </div>
          <QuestionProgressBar
            questionSet={questionSet}
            progressbar={progressbar?.progressbar}
            subQuestionColors={subQuestionColors}
          />
          {isDefferedConfirm && (
            <ConfirmPopup
              showDelete={true}
              attempt={questionData?.totalAttempts}
              yesCallback={handleExitForNow}
              noCallback={() => setIsDefferedConfirm(false)}
            />
          )}

          {/* question set with video tag */}
          <AnswerQuestion
            skillNameRef={skillNameRef}
            questionTextRef={questionTextRef}
            activeTimerRef={activeTimerRef}
            micImgRef={micImgRef}
            setSingleTypeValue={setSingleTypeValue}
            singleTypeValue={singleTypeValue}
            setMultipleTypeValue={setMultipleTypeValue}
            multipleTypeValue={multipleTypeValue}
            setEssayTypeValue={setEssayTypeValue}
            essayTypeValue={essayTypeValue}
            SpeechRecognition={SpeechRecognition}
            browserSupportsSpeechRecognition={browserSupportsSpeechRecognition}
            transcript={transcript}
            questionData={questionData}
            subTimer={subTimer}
            currentQuestionIndex={currentQuestionIndex}
            transcriptEnabled={transcriptEnabled}
            codingAnsTemplate={codingAnsTemplate}
            setCodingAnsTemplate={setCodingAnsTemplate}
            codeOutPut={codeOutPut}
            testCases={testCases}
            setTestCases={setTestCases}
            setCodeOutput={setCodeOutput}
            handleSubmitAnswer={handleSubmitAnswer}
            startQuestionLoader={startQuestionLoader}
            setStartQuestionLoader={setStartQuestionLoader}
            isBlinking={isBlinking}
            SetIsCodingQuestionEdited={SetIsCodingQuestionEdited}
            isStartRecording={isStartRecording}
          />
          <div className="iteration mt-5 mb-3">
            {questionData?.isCustomQuestion ? (
              <div></div>
            ) : (
              <div>
                <p
                  onClick={() => {
                    setIsReport(!isReport);
                  }}
                >
                  {t("SUBMIT_ASSESSMENT_SOMETHING_WRONG_WITH_THIS_QUESTION")}
                  <span className="reportIt" ref={reportBtnRef}>
                    {t("SUBMIT_ASSESSMENT_REPORT_THIS_QUESTION")}
                  </span>
                </p>
              </div>
            )}
            <div className="d-flex gap-3">
              {currentQuestionIndex < assessmentDetailsData?.totalQuestions && (
                <div ref={skipBtnRef}>
                  <AssessmentButton
                    className="skipButton"
                    onClick={() => {
                      setSessionStorageItem("isStatusUpdate", "skipped");
                      setIsStatus("skipped");
                      handleAnswer();
                    }}
                    disabled={
                      isCheckCompatibilities ||
                      !isTextToSpeechFinished ||
                      startQuestionLoader
                    }
                  >
                    {t("GENERAL.SKIP")}
                  </AssessmentButton>
                </div>
              )}
              {/* only for skip and submit */}
              {assessmentDetailsData?.totalQuestions ===
                currentQuestionIndex && (
                  <AssessmentButton
                    disabled={
                      isCheckCompatibilities ||
                      !isTextToSpeechFinished ||
                      startQuestionLoader
                    }
                    onClick={() => {
                      setSessionStorageItem("isStatusUpdate", "skipped");
                      setIsStatus("");
                      handleAnswer();
                    }}
                  >
                    {t("GENERAL.SKIP_AND_SUBMIT")}
                  </AssessmentButton>
                )}
              <div ref={nextQuestRef}>
                <AssessmentButton
                  disabled={
                    questionData?.type === "Video" &&
                      isFirefox &&
                      isTextToSpeechFinished
                      ? false
                      : (essayTypeValue?.length > charLimit ? true : false) ||
                      isCheckCompatibilities ||
                      !isTextToSpeechFinished ||
                      disableNext
                  }
                  onClick={() => {
                    setDisableNext(true);
                    setSessionStorageItem("isStatusUpdate", "answered");
                    setIsStatus("answered");
                    handleAnswer();
                  }}
                >
                  {assessmentDetailsData?.totalQuestions ===
                    currentQuestionIndex
                    ? t("GENERAL.SUBMIT")
                    : t("GENERAL.NEXT.QUESTION")}
                </AssessmentButton>
              </div>
            </div>
          </div>
          {isReport && (
            <div>
              <AddReport
                value={addReport}
                placeHolder={t("SUBMIT_ASSESSMENT.REPORT_YOUR_QUERY")}
                btnName={t("GENERAL.REPORT")}
                setFn={setAddReport}
                handleSubmit={handleSubmitReport}
              />
            </div>
          )}
        </div>

        {isCheckCompatibilities && (
          <CustomModal
            show={isCheckCompatibilities}
            isSaveEnable={true}
            instructionHeadTitle={t("SYSTEM_COMPATIBILITY_CHECKS")}
            saveButtonText="Resume"
            handleSave={handleSystemModalSave}
            isSaveDisable={isResumeDisable}
          >
            <div className="compatibilityModal">
              <SystemCompatibility
                micPermissions={micPermissions}
                checkMicPermissions={() => { }}
                checkCameraPermission={() => { }}
                checkInternetSpeed={checkInternetSpeed}
                cameraPermissions={cameraPermissions}
                internetSpeed={internetSpeed}
                internetSpeedIsLoading={internetSpeedIsLoading}
                retestInternetConnect={retestInternetConnect}
                internetSpeedCount={internetSpeedCount}
                capturedPic={true}
                captureImageCallBack={() => { }}
                videoRef={videoContinueRef}
                headerVisible={false}
              />
            </div>
          </CustomModal>
        )}
        <DeviceDetector
          onDeviceChange={handleDeviceChange}
          videoRef={videoContinueRef}
          isCheckCamerapermission={true}
          isCheckMicpermission={true}
          isCheckInternetpermission={true}
        />
      </div>

      {isTabSwitchedBack && (
        <CustomModal
          show={isTabSwitchedBack}
          isSaveEnable={true}
          saveButtonText={t("GENERAL.OK")}
          isHeaderVisible={false}
          isFooterVisible={true}
          handleSave={handleTabChangeModalSave}
        >
          <div className="tabchangepopup">
            <span>{t("SUBMIT_ASSESSMENT_TAB_CHANGE_TEXT")}</span>
          </div>
        </CustomModal>
      )}
      {isAbortedPopup && (
        <CustomModal
          show={isAbortedPopup}
          isSaveEnable={true}
          saveButtonText={t("GENERAL.OK")}
          isHeaderVisible={false}
          isFooterVisible={true}
          handleSave={handleAbortedModalSave}
        >
          <div className="tabchangepopup">
            <div className="reportedPopup">
              <span>{t("GENERAL.ALERT")}</span>
              <span>{t("SUBMIT_ASSESSMENT_ABORTED_TEXT")}</span>
            </div>
          </div>
          {/* <div className="abortedTimer">{abortedTimer}</div> */}
        </CustomModal>
      )}
      <Footer />
    </>
  );
};

export default QuestionList;
